document.getElementById("submit-din").onclick = function (e) {
  e.preventDefault();
  const din = ["02182904", "02243948", "02242441", "02245641"];
  const userInput = document.getElementById("din-input").value;
  const dinInput = document.getElementById("din-input");
  const err = document.getElementById("error-msg");
  const errMobile = document.getElementById("error-msg-mobile");
  const errEmpty = document.getElementById("error-empty");
  const errMobileEmpty = document.getElementById("error-empty-mbl");
  err.classList.add("hidden");
  errEmpty.classList.add("hidden");
  errMobile.classList.add("hidden");
  errMobileEmpty.classList.add("hidden");
  dinInput.classList.remove("error");
  if (din.indexOf(userInput) >= 0) {
    if (din.indexOf(userInput) === 1 || din.indexOf(userInput) === 2) {
      window.location.href = "https://puregon.ca/";
    }
    if (din.indexOf(userInput) === 0) {
      window.location.href = "https://pregnyl.ca/";
    }
    if (din.indexOf(userInput) === 3) {
      window.location.href = "https://orgalutran.ca/";
    }
  } else {
    if (userInput === "" || userInput === null || userInput === undefined) {
      errEmpty.classList.remove("hidden");
      errMobileEmpty.classList.remove("hidden");
    } else {
      err.classList.remove("hidden");
      errMobile.classList.remove("hidden");
    }
    dinInput.classList.add("error");
  }
};
